.option {
  padding: 10px 20px;
  border: 1px solid #666666;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.option.active {
  background-color: #FB861E; /* Change to your desired color */
  color: white; /* Optional: Change text color for better contrast */
}