.bgimg{
    background-image: url('./assets/s1.svg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat; 
}
.hover-zoom:hover {
    transform: scale(1.1);
    transition: transform 0.3s ease-in-out;
}
.getbg{
    background-image: url('./assets/g1.svg');
    background-position: center;
    background-size: cover;
}
.getbg::before{
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0%;
    bottom: 0%;
    background-color: #007BFF;
    opacity: 0.4;

}

.lift-up {
    transform: translateY(-20px);
    transition: transform 0.5s ease;
  }
  