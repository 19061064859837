.bgimage {
  background-image: url("./assets/l1.svg");
  background-position: center;
  background-size: cover;
  position: relative;
  z-index: 0;
}
.bgimage::before {
  content: "";
  z-index: 5;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0%;
  bottom: 0%;
  background-color: #007bff;
  opacity: 10%;
}

.plane-icon {
  background-image: url("./assets/Vector.svg");
  background-size: 360px 50px;
  overflow: hidden;
}

.logo {
  background-image: url("./assets/logo.svg");
  background-size: 100%;
  overflow: hidden;
  background-position-x: center;
}

.email-icon {
  background-image: url("./assets/sms.svg");
  background-repeat: no-repeat;
  background-position: 10px center;
  background-size: 20px 20px;
  padding-left: 40px;
}

.password-icon {
  background-image: url("./assets/lock.svg");
  background-repeat: no-repeat;
  background-position: 10px center;
  background-size: 20px 20px;
  padding-left: 40px;
}

.google-icon {
  background-image: url("./assets/google.svg");
  background-repeat: no-repeat;
  background-position: 20px center;
  background-size: 20px 20px;
  padding-left: 60px;
  display: inline-flex;
}

.google-signin {
  font-size: 110%;
  text-align: center;
}

@media (max-width: 995px) {
  .google-signin {
    font-size: 80% !important;
  }
  .google-icon {
    background-position: 20% center;
    background-size: 12px 20px;
    padding-left: 20%;
  }
}

@media (max-width: 870px) {
  .google-signin {
    font-size: 65% !important;
  }
}

@media (max-width: 639px) {
  .bgimage {
    height: 40vh;
    margin-bottom: 40px;
  }
  .logo {
    background-size: 220px;
  }
  .google-signin {
    font-size: 80% !important;
    text-align: center;
    align-self: center;
    align-items: center;
    justify-content: center;
  }
  .google-icon {
    padding-left: 50px;
    background-position: 30px center;
  }
}

@media (max-height: 520px) {
  .plane-icon {
    display: none;
  }
}

@media (max-height: 520px) {
  .plane-icon {
    display: none;
  }
}

.error-message {
  text-align: left;
  color: red;
  font-size: 12px;
  margin-top: -10px;
  width: 60%;
  margin-left: 0;
}
